<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Blog Post Statuses'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Blog Post Status Edit'"
      :title="'Blog Post Status Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Update</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import blogApi from "~/api/blog";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";

export default {
  name: "BlogPostStatusEdit",
  metaInfo: {
    title: "Blog Post Status Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
  },
  mounted() {
    this.getItem();
  },
  computed: {
    ...mapState("blog", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.BLOG_POST_STATUSES_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
      };
      const url = `/${this.getId}`;
      this.$store.dispatch("blog/editBlogPostStatus", { url: url, editData: data });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.BLOG_POST_STATUSES_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        blogApi.getItemBlogPostStatus(url).then((res) => {
          this.$store.commit("blog/setError", {});
          this.$store.commit("blog/setValidationErrors", {});
          const data = res.data;
          this.formDataToSend.name = data.name;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
